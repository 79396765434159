@tailwind base;
@tailwind components;
@tailwind utilities;

.markdown h1 {
  @apply mb-4 mt-8 text-4xl font-bold;
}

.markdown h2 {
  @apply mb-4 mt-8 text-2xl font-bold;
}

.markdown p {
  @apply my-2;
}
.markdown ol {
  counter-reset: count 0;
}

.markdown ol > li {
  /* @apply my-2; */
}
.markdown ol > li:before {
  content: '(' counter(count) ') ';
  counter-increment: count;
}

.markdown ul > li {
  @apply ml-4 list-outside list-disc;
}

.markdown ol ul > li {
  @apply ml-8;
}
